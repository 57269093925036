
export default () => {

    $('.l-nav:not(.js-nav-done)').each(function() {
        var wrapper = $(this);
        var links = wrapper.find('.js-nav-link');
        var subs = wrapper.find('.js-sub-nav');
        var hoverItem = wrapper.find('.js-nav-item:not(.js-nav-item--search)');

        // link click
        links.off('click').on('click', function() {
            var link = $(this);
            var sub = link.next('.js-sub-nav:first', 'js-sub-nav:not(js-sub-nav--search)');
            if (!sub.length) return;

            if (link.parent().is('.hover')) { // open
                link.parent().removeClass('hover');
                sub.hide();
            }
            else { // closed
                links.parent().removeClass('hover');
                subs.hide();
                link.parent().addClass('hover');
                sub.show();
            }

            return false;
        });

        // anywhere click
        window.site.body.on('click', function(e) {
            if ($(e.target).closest('.l-nav').length === 0) {
                links.parent().removeClass('hover');
                subs.hide();
            }
        });

        // mouse out of list item
        hoverItem.on('mouseleave', function(e) {
            setTimeout(function() {
                links.parent().removeClass('hover');
                subs.hide();
            }, 500)
        });

        // done
        wrapper.addClass('js-nav-done');
    });

}
